/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import {
    object, shape, arrayOf, string, number, bool,
} from 'prop-types';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { uid } from 'react-uid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { makeStyles } from '@material-ui/core';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import {  useDispatch, useSelector } from 'react-redux';
import { getIsAuthenticatedStatus } from '../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { handleDoNotSellMyInformationClick } from '../../../../helpers/tracking/common/commonTrackingHelpers';
import { trackEvent } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const useStyles = makeStyles(({ palette }) => ({
    addIcon: {
        justifyContent: 'flex-end',
        display: 'flex',
        color: palette.black,
        '& svg': {
            fontSize: '29px',
        },
    },
    mainLink: {
        padding: '14px 5px 14px 0px',
        borderBottom: `1px solid ${palette.cms?.tertiary_hover}`,
        margin: '4px 13px',
        '& a': {
            color: palette?.black,
        },
    },
    childLink: {
        margin: '9px 13px',
        padding: '4px 0px',
        '& a': {
            color: palette?.black,
        },
    },
    detailContainer: {
        marginLeft: '15px',
        padding: '0px',
    },
    panelSummary: {
        backgroundColor: 'inherit',
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
        margin: '0px 12px',
        padding: '0px',
        '& h6': {
            fontSize: '18px',
        },
        borderBottom: `1px solid ${palette.cms?.tertiary_hover}`,
    },
    panelContainer: {
        boxShadow: 'none',
        backgroundColor: 'inherit',
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
}));

const MobileFooterMenu = ({ menuData }) => {
    const [accordionActive, setAccordionActive] = useState();
    const classes = useStyles();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);

    const handleChangeExpansionPanel = (panel) => (event, newExpanded) => {
        setAccordionActive(newExpanded ? panel : null);
    };
    const renderMainLink = (mainLinkObj, indexMenuParentItem, className = '', tracking_event_action, tracking_event_category, tracking_event_label) => {
        if ((mainLinkObj?.title === 'Sign In' && isAuthenticated) || (mainLinkObj?.title === 'Sign Out' && !isAuthenticated)) {
            return null;
        }
        const footerLinkClickHandler = (mainLinkObject) => {
            let origin = '';
            if (typeof window !== 'undefined' && window.location && window.location.origin) {
                origin = window.location.origin;
            }
            dispatch(trackEvent({
                eventCategory: tracking_event_category || 'Global Footer',
                eventAction: tracking_event_action || mainLinkObject?.title || '',
                eventLabel: tracking_event_label || mainLinkObject?.title || '',
                link_url: `${origin}${mainLinkObj?.href}`,
            }));
            if (mainLinkObject?.href?.includes('submit-irm.trustarc.com')) return handleDoNotSellMyInformationClick();

            return undefined;
        };

        return (
            <Grid key={uid(mainLinkObj, indexMenuParentItem)} item xs={12} className={className}>
                { mainLinkObj?.href.includes('://') ? (
                    <Typography
                        component="a"
                        href={mainLinkObj?.href}
                        onClick={() => footerLinkClickHandler(mainLinkObj)}
                        variant="body1"
                    >
                        {mainLinkObj?.title}
                    </Typography>
                ) : (
                    <Link
                        to={mainLinkObj?.href}
                        onClick={() => footerLinkClickHandler(mainLinkObj)}
                    >
                        <Typography variant="body1">{mainLinkObj?.title}</Typography>
                    </Link>
                )}
            </Grid>
        );
    };

    const renderChildrenFooter = (menuItemObj, menuTitle, indexMenuParentItem) => {
        const checkActiveTab = (accordionActive === indexMenuParentItem);
        const tracking = {};
        return (
            <Grid item md={12} xs={12} key={uid(menuTitle, indexMenuParentItem)}>
                <MuiExpansionPanel className={classes.panelContainer}expanded={checkActiveTab} onChange={handleChangeExpansionPanel(indexMenuParentItem)}>
                    <MuiExpansionPanelSummary  className={classes.panelSummary} aria-controls={`mobile-footer-${indexMenuParentItem}`}>
                        <Grid container>
                            <Grid item md={11} xs={10}>
                                <Typography variant="body1">{menuTitle}</Typography>
                            </Grid>
                            <Grid item md={1} xs={2} className={classes.addIcon}>
                                {(checkActiveTab) ? <RemoveIcon /> : <AddIcon />}
                            </Grid>
                        </Grid>
                    </MuiExpansionPanelSummary>
                    <MuiExpansionPanelDetails className={classes.detailContainer}>
                        <Grid item md={12} xs={12}>
                            {(menuItemObj && menuItemObj.length > 0)
                                && menuItemObj.map((subMenuItem, subMenuItemIndex) => {
                                    const link = renderMainLink(
                                        subMenuItem?.link,
                                        subMenuItemIndex,
                                        classes.childLink,
                                        tracking.tracking_event_action = subMenuItem.tracking_event_action || '',
                                        tracking.tracking_event_category = subMenuItem.tracking_event_category || '',
                                        tracking.tracking_event_label = subMenuItem.tracking_event_label || '',
                                    );
                                    return link;
                                })}
                        </Grid>
                    </MuiExpansionPanelDetails>
                </MuiExpansionPanel>
            </Grid>
        );
    };

    const renderFooterMenu = (menuBlockData) => (menuBlockData.map((menuParentItem, indexMenuParentItem) => {
        const menuGroups = menuParentItem?.menu_groups;
        if (menuGroups) {
            const menuItem = menuGroups?.menu_item;
            const mainLink = menuGroups?.main_link;
            const menuTitle = menuGroups?.title;
            const tracking = {
                tracking_event_action: menuGroups.tracking_event_action || '',
                tracking_event_category: menuGroups.tracking_event_category || '',
                tracking_event_label: menuGroups.tracking_event_label || '',
            };
            return ((menuItem && menuItem.length > 0) ? renderChildrenFooter(menuItem, menuTitle, indexMenuParentItem, tracking) : renderMainLink(mainLink, indexMenuParentItem, classes.mainLink, tracking));
        }
        return null;
    }));

    const menuBlockData = menuData?.menu_group_link_ref[0]?.menu_blocks;
    return ((menuBlockData && menuBlockData.length > 0) ? renderFooterMenu(menuBlockData) : <></>);
};

MobileFooterMenu.propTypes = {
    menuData: shape({
        menu_group_link_ref: arrayOf(shape({
            background_color: object,
            default_text_color: object,
            locale: string,
            menu_blocks: arrayOf(shape({
                menu_groups: shape({
                    column: number,
                    icon: string,
                    is_heading: bool,
                    link: object,
                    link_style: object,
                    placement: string,
                    sub_menu: bool,
                }).isRequired,
            })).isRequired,
        })).isRequired,
    }).isRequired,
};

export default MobileFooterMenu;
