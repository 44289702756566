/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    object, array, func, string, bool, any, shape,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import CustomerServiceBlock from '../Partials/CustomerServiceBlock';
import TermsModal from '../Partials/BottomMenuModal';
import MobileFooterMenu from '../Partials/MobileFooterMenu';
import SocialLinksBlock from '../Partials/SocialLinksBlock';
import { handleDoNotSellMyInformationClick } from '../../../../helpers/tracking/common/commonTrackingHelpers';

const styles = (theme) => ({
    copyright: {
        color: theme.palette.copyright || '#747472',
        margin: '10px',
        textAlign: 'center',
        fontSize: '12px',
    },
    foodBrandCopyright: {
        margin: '10px 10px 0px 10px',
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        textAlign: 'center',
        fontSize: '13px',
    },
    bottomMenu: {
        width: '100%',
        textAlign: 'center',
    },
    bottomMenuSeperator: {
        '& span:not(:last-child):not(:first-child):after': {
            content: "'|'",
            margin: '0px 5px',
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
    },
    bottomMenuLink: {
        fontSize: '14px',
        color: theme.palette.cms?.mobileFooterBottomLink || theme.palette.mobileFooterBottomLink || '#747472',
        textAlign: 'center',
        display: 'inline-block',
        textDecoration: 'underline',
        textTransform: 'uppercase',
        padding: '5px 10px',
        margin: '5px 0px',
    },
    foodBrandBottomMenuLink: {
        display: 'inline-block',
        textDecoration: 'underline',
        color: theme.palette.cms?.mobileFooterBottomLink || theme?.palette.mobileFooterBottomLink || '#747472',
        textAlign: 'center',
        fontSize: '13px',
    },
    bottomMenuText: {
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        textAlign: 'center',
        fontSize: '13px',
    },
    copyRightBottomLink: {
        color: theme.palette.copyright || '#747472',
        padding: '0 0 0 4px',
        textDecoration: 'underline',
    },
});
const triggerClick = (self, setPopupTitle, title) => {
    setPopupTitle({ title, self });
};
// Check this function for <a> or <Link>
const footerBottomLink = (item, classes, presentationFamily) => {
    if (!item) {
        return null;
    }
    const { link: { href, title } } = item;
    const foodPresentation = (presentationFamily === 'food');
    let getFooterLink;
    if (href.indexOf('http://') === 0 || href.indexOf('https://') === 0) {
        getFooterLink = (
            <a
                key={`${href}${title}`}
                href={href}
                rel="noreferrer"
                onClick={() => {
                    if (href?.includes('submit-irm.trustarc.com')) {
                        handleDoNotSellMyInformationClick();
                    }
                }}
                className={(foodPresentation) ? classes.foodBrandBottomMenuLink : classes.bottomMenuLink}
            >
                {title}
            </a>
        );
    } else if (href.indexOf('About-Us-Terms-of-Use') !== 1 && href.indexOf('About-Us-Privacy-Policy') !== 1) {
        getFooterLink = (
            <Link
                key={`${href}${title}`}
                className={classes.productLink}
                to={href}
            >
                <span className={(foodPresentation) ? classes.foodBrandBottomMenuLink : classes.bottomMenuLink}>
                    {title}
                </span>
            </Link>
        );
    } else {
        return null;
    }
    return (
        getFooterLink
    );
};
const copyRightBottomLink = (item, classes) => {
    if (!item) {
        return null;
    }
    const { link: { href, title } } = item;
    let copyRightLink;
    if (href.indexOf('About-Us-Terms-of-Use') && href.indexOf('About-Us-Privacy-Policy')) {
        copyRightLink = (
            <Link
                key={`${href}${title}`}
                className={classes.copyRightBottomLink}
                to={href}
            >
                {title}
            </Link>
        );
    } else {
        return null;
    }
    return (
        copyRightLink
    );
};
const MobileFooterBlock = ({
    block, classes, setPopupTitle, popUpData, title, self, openDialog, setDialog, presentationFamily, bottomLinks, isFooterV2, chatBlock,
}) => {
    const blockKey = Object.keys(block)[0];
    const blockObj = block[blockKey];
    const foodPresentation = (presentationFamily === 'food');
    switch (blockKey) {
        case 'phone':
            return (
                <CustomerServiceBlock
                    phoneNumber={blockObj?.link?.href}
                    chatBlock={chatBlock}
                    foodPresentation={foodPresentation}
                />
            );
        case 'menu_group_links':
            return (
                (foodPresentation || isFooterV2) && (<MobileFooterMenu menuData={blockObj} />)
            );
        case 'social_links':
            return (
                (foodPresentation) && (<SocialLinksBlock data={blockObj} />)
            );
        case 'footer_bottom_links':
            return (
                <div className={`${classes.bottomMenu} ${(foodPresentation) ? classes.bottomMenuSeperator : ''}`}>
                    {(foodPresentation) && <span className={classes.bottomMenuText}> Family of Brands - </span>}
                    {blockObj.link_group.map((item) => (item.popup && foodPresentation ? (
                        <span
                            key={`${item.link.href}${item.link.title}`}
                            onClick={() => {
                                triggerClick(self, setPopupTitle, item.popup_title);
                            }}
                            role="presentation"
                            className={classes.foodBrandBottomMenuLink}
                        >
                            {item.link.title}
                        </span>
                    ) : (
                        footerBottomLink(item, classes, presentationFamily)
                    )))}
                    <TermsModal self={self} setDialog={setDialog} title={title} show={openDialog} data={popUpData} />
                </div>
            );
        case 'copyright':
            return (
                <div className={(foodPresentation) ? classes.foodBrandCopyright : classes.copyright}>
                    {blockObj.copy}
                    {bottomLinks?.map((item, i) => (item.popup ? (
                        <span
                            key={`${item.link.title}`}
                            onClick={() => {
                                triggerClick(self, setPopupTitle, item.popup_title);
                            }}
                            role="presentation"
                            className={classes.copyRightBottomLink}
                            title={item.link.title}
                        >
                            {item.link.title}
                        </span>
                    ) : (
                        <>
                            {!foodPresentation && i > 0 && <span> -</span>}
                            {copyRightBottomLink(item, classes)}
                        </>
                    )))}
                </div>
            );
        default:
            return null;
    }
};
MobileFooterBlock.propTypes = {
    block: object.isRequired,
    classes: object.isRequired,
    popUpData: array,
    title: string,
    openDialog: bool,
    setDialog: func,
    setPopupTitle: func,
    self: any,
    presentationFamily: string.isRequired,
    bottomLinks: array,
    isFooterV2: bool,
    chatBlock: shape({
        chat: shape({
            link: shape({
                href: string.isRequired,
            }).isRequired,
            icon: shape({
                content_type: string.isRequired,
                url: string.isRequired,
            }).isRequired,
        }).isRequired,
    }),
};
MobileFooterBlock.defaultProps = {
    popUpData: [],
    title: '',
    openDialog: false,
    setDialog: () => { },
    setPopupTitle: () => { },
    bottomLinks: [],
    self: null,
    isFooterV2: false,
    chatBlock: {},
};
export default withStyles(styles)(MobileFooterBlock);
